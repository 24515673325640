<template>
    <div class="app-body">
        <bread-crumb></bread-crumb>
        <el-card class="el-main deviceAdd">
            <el-form ref="deviceAddForm" :model="form" :rules="rules">
                <div class="a-fs-16 a-fw-700">设备信息</div>
                <el-form-item class="a-mt-32" label="" prop="deviceCategroy">
                    <le-select-remote-search class="editDevType" notnull label="设备种类" v-model="form.deviceCategroy"
                        :options="optionsDeviceCategroy" placeholder="请选择（可搜索）" />
                </el-form-item>
                <el-form-item class="a-mt-32" label="" prop="deviceType">
                    <le-select-remote-search class="editDevType" notnull label="设备类型" v-model="form.deviceType"
                        :options="optionsDeviceType" placeholder="请选择（可搜索）" />
                </el-form-item>
                <el-form-item label="" prop="slotNum" v-if="form.deviceCategroy == 1 || form.deviceCategroy == 4 || form.deviceCategroy == 5">
                    <le-input-num notnull class="editDevType" label="设备插槽数" v-model="form.slotNum" :maxlength="15"
                        placeholder="请输入"></le-input-num>
                </el-form-item>
                <el-form-item label="" prop="canFace" v-if="(form.deviceCategroy == 2)">
                    <le-select-local-search notnull class="editDevType" label="是否人脸" v-model="form.canFace" :options="yesOrNoDic" />
                </el-form-item>
                <el-form-item label="" prop="canRfid" v-if="(form.deviceCategroy == 2)">
                    <le-select-local-search notnull class="editDevType" label="是否RFID" v-model="form.canRfid" :options="yesOrNoDic" />
                </el-form-item>
                <el-form-item label="" prop="deviceCode">
                    <le-input notnull class="editDevType" label="设备编码" v-model="form.deviceCode" placeholder="请输入">
                    </le-input>
                </el-form-item>
                <el-form-item label="" prop="imei">
                    <le-input notnull class="editDevType" label="IMEI/厂商ID" v-model="form.imei" placeholder="请输入" :maxlength="24">
                    </le-input>
                </el-form-item>
                <el-form-item label="" prop="serialNumber">
                    <le-input class="editDevType" label="序列号" v-model="form.serialNumber" placeholder="请输入"></le-input>
                </el-form-item>
                <el-form-item label="" prop="iccid">
                    <le-input class="editDevType" label="ICCID" v-model="form.iccid" placeholder="请输入"></le-input>
                </el-form-item>
                <el-form-item label="" prop="softwareVersion">
                    <le-input class="editDevType" label="软件版本" v-model="form.softwareVersion" placeholder="请输入">
                    </le-input>
                </el-form-item>
                <el-form-item label="" prop="hardwareVersion">
                    <le-input class="editDevType" label="硬件版本" v-model="form.hardwareVersion" placeholder="请输入">
                    </le-input>
                </el-form-item>
                <el-form-item label="" prop="canUse">
                    <le-switch label="设备状态" v-model="form.canUse" actext="启用" inactext="禁用" :acValue="1" :inacValue="0">
                    </le-switch>
                </el-form-item>
                <el-form-item label="" prop="mark">
                    <le-input label="备注" class="editDevType" v-model="form.mark" :maxlength="10" placeholder="请输入">
                    </le-input>
                </el-form-item>
                <div style="height:100px"></div>
                <div class="a-line-t-e0 footerBox">
                    <el-button type="primary" class="a-ml-24 a-mt-15" @click="submit">
                        &nbsp;&nbsp;&nbsp;确定&nbsp;&nbsp;&nbsp;</el-button>
                    <el-button class="a-ml-24 a-mt-15" @click="cancel" style="margin-left:24px">
                        &nbsp;&nbsp;&nbsp;取消&nbsp;&nbsp;&nbsp;</el-button>
                </div>
            </el-form>
        </el-card>
    </div>
</template>
<script>

export default {
    data() {
        return {
            
            optionsDeviceCategroy: {
                url: this.$Config.apiUrl.getDeviceCategroyList,
                method: "post",
                params: {},
                showLoading: false,
                labelKey: "deviceCategroy",
                valueKey: "id",
                searchKey: "key",
            },
            optionsDeviceType: {
                url: this.$Config.apiUrl.getDeviceType,
                method: "post",
                params: {
                    deviceCategroy: ''
                },
                showLoading: false,
                labelKey: "name",
                valueKey: "id",
                searchKey: "key",
            },
            form: {
                deviceCategroy: '',//设备种类
                deviceType: '',//设备类型
                slotNum: '',// 设备插槽数
                deviceCode: '',//设备编码
                imei: '',//IMEI/厂商ID
                serialNumber: '',//序列号
                iccid: '',
                id: '',//设备ID
                hardwareVersion: '',//硬件版本
                softwareVersion: '',//软件版本
                canUse: '',//设备状态
                mark: '',
                canFace:'',
                canRfid:''
            },
            rules: {
                deviceCategroy: [{ required: true, message: '请选择设备种类', trigger: 'change' }],
                deviceType: [{ required: true, message: '请选择设备类型', trigger: 'change' }],
                slotNum: [{ required: true, message: '请输入设备插槽数', trigger: 'blur' }],
                deviceCode: [{ required: true, message: '请输入设备编码', trigger: 'blur' }],
                imei: [{ required: true, message: '请输入IMEI/厂商ID', trigger: 'blur' }],
                canRfid: [{ required: true, message: '请选择是否RFID', trigger: 'change' }],
                canFace: [{ required: true, message: '请选择是否人脸', trigger: 'change' }],
            },
            yesOrNoDic: []
        }
    },
    watch: {
        'form.deviceCategroy':{
            deep: true,
            handler (newVal, oldVal) {
                if(newVal != oldVal){
                    this.form.deviceType = ''
                    this.optionsDeviceType.params.deviceCategroy = newVal
                }
            }
        }
    },
    created () {
        this.$getDic('yesOrNo','select').then(res=>{ this.yesOrNoDic = res; })
    },
    mounted() {
        this.form.deviceCode = this.$route.query.deviceCode
        if (this.form.deviceCode) this.getDevInfo()
    },
    activated () {
        this.form.deviceCode = this.$route.query.deviceCode
        if (this.form.deviceCode) this.getDevInfo()
    },
    methods: {
        getDevInfo() {
            this.$Axios._get({
                url: this.$Config.apiUrl.getDeviceBaseInfo,
                method: "get",
                params: {
                    deviceCode: this.form.deviceCode
                }
            }).then(res => {
                console.log(res);
                if (res.result.code == 0) {
                    this.form.deviceCategroy = res.result.data.deviceCategroy
                    this.form.imei = res.result.data.imei
                    this.form.serialNumber = res.result.data.serialNumber
                    this.form.iccid = res.result.data.iccid
                    this.form.id = res.result.data.deviceId
                    this.form.hardwareVersion = res.result.data.hardwareVersion
                    this.form.softwareVersion = res.result.data.softwareVersion
                    this.form.canUse = res.result.data.canUse
                    this.form.slotNum = res.result.data.slotNum
                    this.form.mark = res.result.data.mark
                    this.form.canFace = JSON.stringify(res.result.data.canFace)
                    this.form.canRfid = JSON.stringify(res.result.data.canRfid)
                    
                    this.$nextTick(()=>{
                        this.form.deviceType = res.result.data.deviceType
                    })
                } else {
                    this.$message.error(res.result.message)
                }
            })
        },
        submit() {
            this.$refs['deviceAddForm'].validate((valid) => {
                if (valid) {
                    this.$Axios._post({
                        url: this.$Config.apiUrl.editDevice,
                        method: "post",
                        params: this.form
                    }).then(res => {
                        if (res.result.code == 0) {
                            this.$route.meta.hash = "del";
                            this.$message.success('编辑成功')
                            this.$router.back()
                        } else {
                            this.$message.error(res.result.message)
                        }
                    })
                }
            })
        },
        cancel() {
            this.$router.back()
        }
    }
}
</script>

<style lang="scss" scoped>
/deep/ .s-search-label {
    width: 92px !important;
    justify-content: flex-start;
}

.deviceAdd {
    padding-bottom: 100px
}

.editDevType {
    padding: 0 0 6px 0 !important;
    width: 350px !important;
}
</style>